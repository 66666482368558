<template>
  <div id="mian">
    <div class="header">
      <span
        @click="navClick('下单')"
        :class="crumbs == '下单' ? 'active' : 'left'"
        >下单</span
      >
      <span
        @click="navClick('订单查询')"
        :class="crumbs == '订单查询' ? 'active' : 'left'"
        >订单查询</span
      >
      <span
        @click="navClick('退款')"
        :class="crumbs == '退款' ? 'active' : 'left'"
        >退款</span
      >
      <span
        @click="navClick('退款查询')"
        :class="crumbs == '退款查询' ? 'active' : 'left'"
        >退款查询</span
      >
    </div>
    <div>
      <div class="desc">
        <h5 class="jsxx">请求信息</h5>
      </div>
      <div class="input_con" v-if="crumbs == '下单'">
        <ul class="inputBox">
          <li>
            <span><i>*</i>商户号</span>
            <el-input
              class="inputs"
              v-model="formData1.mch_id"
              maxlength="32"
              placeholder="输入商户号"
              tabindex="1"
            ></el-input>
          </li>
          <li>
            <span><i>*</i>商品名称</span>
            <el-input
              class="inputs"
              v-model="formData1.body"
              maxlength="32"
              placeholder="输入商品名称"
              tabindex="2"
            ></el-input>
          </li>
          <li>
            <span><i>*</i>商户订单号</span>
            <el-input
              class="inputs"
              v-model="formData1.out_trade_no"
              maxlength="32"
              placeholder="输入商户订单号"
              tabindex="3"
            ></el-input>
          </li>
          <li>
            <span><i>*</i>总金额(元)</span>
            <el-input
              class="inputs"
              type="number"
              onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
              min="0.01"
              v-model="formData1.total_fee"
              placeholder="输入总金额"
              tabindex="4"
            ></el-input>
          </li>
          <li>
            <span><i>*</i>通知地址</span>
            <el-input
              class="inputs"
              v-model="formData1.notify_url"
              maxlength="256"
              placeholder="输入通知地址"
              tabindex="5"
            ></el-input>
          </li>
          <li>
            <span><i>*</i>终端IP</span>
            <el-input
              class="inputs"
              v-model="formData1.spbill_create_ip"
              maxlength="16"
              placeholder="终端IP"
              tabindex="6"
            ></el-input>
          </li>
          <li>
            <span>收银台</span>
            <el-select
              class="inputs"
              v-model="formData1.cashier_type"
              placeholder="选择交易类型"
              tabindex="7"
            >
              <el-option label="开启" :value="1"></el-option>
              <el-option label="关闭" :value="0"></el-option>
            </el-select>
          </li>
          <li>
            <span>附加数据</span>
            <el-input
              class="inputs"
              v-model="formData1.attach"
              maxlength="128"
              placeholder="输入附加数据"
              tabindex="8"
            ></el-input>
          </li>
          <li>
            <span><i>*</i>产品类型</span>
            <el-select
              class="inputs"
              v-model="formData1.trade_type"
              placeholder="选择产品类型"
              tabindex="9"
            >
              <el-option
                v-for="(v, i) in payDropLists"
                :key="i"
                :label="v.productName"
                :value="v.productValue"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span>门店编号</span>
            <el-input
              class="inputs"
              v-model="formData1.op_shop_no"
              maxlength="32"
              placeholder="输入门店编号"
              tabindex="10"
            ></el-input>
          </li>
          <li>
            <span><i>*</i>密钥</span>
            <el-input
              class="inputs"
              v-model="formData1.key"
              placeholder="输入密钥"
              tabindex="11"
            ></el-input>
          </li>
        </ul>
      </div>
      <div class="input_con" v-if="crumbs == '订单查询'">
        <ul class="inputBox">
          <li>
            <span><i>*</i>商户号</span>
            <el-input
              class="inputs"
              v-model="formData2.mch_id"
              maxlength="32"
              placeholder="输入商户号"
              tabindex="1"
            ></el-input>
          </li>
          <li>
            <span><i>*</i>密钥</span>
            <el-input
              class="inputs"
              v-model="formData2.key"
              placeholder="输入密钥"
              tabindex="11"
            ></el-input>
          </li>
          <li>
            <span>商户订单号</span>
            <el-input
              class="inputs"
              v-model="formData2.out_trade_no"
              maxlength="32"
              placeholder="输入商户订单号"
              tabindex="3"
            ></el-input>
          </li>
          <li>
            <span>交易流水号</span>
            <el-input
              class="inputs"
              v-model="formData2.transaction_id"
              maxlength="32"
              placeholder="输入交易流水号"
              tabindex="2"
            ></el-input>
          </li>
        </ul>
      </div>
      <div class="input_con" v-if="crumbs == '退款'">
        <ul class="inputBox">
          <li>
            <span><i>*</i>商户号</span>
            <el-input
              class="inputs"
              v-model="formData3.mch_id"
              maxlength="32"
              placeholder="输入商户号"
              tabindex="1"
            ></el-input>
          </li>
          <li>
            <span>交易流水号</span>
            <el-input
              class="inputs"
              v-model="formData3.transaction_id"
              maxlength="32"
              placeholder="输入交易流水号"
              tabindex="2"
            ></el-input>
          </li>
          <li>
            <span>商户订单号</span>
            <el-input
              class="inputs"
              v-model="formData3.out_trade_no"
              maxlength="32"
              placeholder="输入商户订单号"
              tabindex="3"
            ></el-input>
          </li>
          <li>
            <span><i>*</i>退款金额</span>
            <el-input
              class="inputs"
              v-model="formData3.refund_fee"
              type="number"
              onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
              min="0.01"
              placeholder="输入退款金额"
              tabindex="4"
            ></el-input>
          </li>
          <li>
            <span><i>*</i>总金额(元)</span>
            <el-input
              class="inputs"
              v-model="formData3.total_fee"
              type="number"
              onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
              min="0.01"
              placeholder="输入总金额"
              tabindex="5"
            ></el-input>
          </li>
          <li>
            <span><i>*</i>通知地址</span>
            <el-input
              class="inputs"
              v-model="formData3.notify_url"
              maxlength="256"
              placeholder="输入通知地址"
              tabindex="6"
            ></el-input>
          </li>
          <li>
            <span><i>*</i>退款订单号</span>
            <el-input
              class="inputs"
              v-model="formData3.out_refund_no"
              maxlength="32"
              placeholder="输入退款订单号"
              tabindex="7"
            ></el-input>
          </li>
          <li>
            <span><i>*</i>密钥</span>
            <el-input
              class="inputs"
              v-model="formData3.key"
              placeholder="输入密钥"
              tabindex="11"
            ></el-input>
          </li>
        </ul>
      </div>
      <div class="input_con" v-if="crumbs == '退款查询'">
        <ul class="inputBox">
          <li>
            <span><i>*</i>商户号</span>
            <el-input
              class="inputs"
              v-model="formData4.mch_id"
              maxlength="32"
              placeholder="输入商户号"
              tabindex="1"
            ></el-input>
          </li>
          <li>
            <span><i>*</i>退款订单号</span>
            <el-input
              class="inputs"
              v-model="formData4.out_refund_no"
              maxlength="32"
              placeholder="输入退款订单号"
              tabindex="2"
            ></el-input>
          </li>
          <li>
            <span>交易流水号</span>
            <el-input
              class="inputs"
              v-model="formData4.transaction_id"
              maxlength="32"
              placeholder="输入交易流水号"
              tabindex="3"
            ></el-input>
          </li>
          <li>
            <span>商户订单号</span>
            <el-input
              class="inputs"
              v-model="formData4.out_trade_no"
              maxlength="32"
              placeholder="输入商户订单号"
              tabindex="4"
            ></el-input>
          </li>
          <li>
            <span>退款流水号</span>
            <el-input
              class="inputs"
              v-model="formData4.refund_id"
              maxlength="32"
              placeholder="输入退款流水号"
              tabindex="5"
            ></el-input>
          </li>
          <li>
            <span><i>*</i>密钥</span>
            <el-input
              class="inputs"
              v-model="formData4.key"
              placeholder="输入密钥"
              tabindex="11"
            ></el-input>
          </li>
        </ul>
      </div>
      <div class="desc" v-if="detail">
        <h5 class="jsxx">返回结果</h5>
      </div>
      <div class="input_con" v-if="detail">
        <div class="list clear">
          <div class="left_con">
            <div class="clear" v-for="(v, i) in detail" :key="i">
              <h4>{{ detailName[i] }}</h4>
              <!-- <h5 v-if="i === 'trade_type'">{{ payDropLists[v] }}</h5>  -->
              <h5 v-if="i === 'trade_type'">{{ productName }}</h5>

              <h5 v-else-if="i === 'is_subscribe'">
                {{ v === "Y" ? "关注" : "未关注" }}
              </h5>
              <h5 v-else-if="i === 'sub_is_subscribe'">
                {{ v === "Y" ? "关注" : "未关注" }}
              </h5>
              <h5 v-else-if="i === 'trade_state'">{{ payStatus[v] }}</h5>
              <h5 v-else-if="i === 'bank_type'">{{ bank[v] }}</h5>
              <h5
                v-else-if="
                  i === 'total_fee' || i === 'cash_fee' || i === 'refund_fee'
                "
              >
                {{ (v / 100) | formatMoney }}
              </h5>
              <h5 v-else-if="i === 'fee_type'">{{ money[v] }}</h5>
              <h5 v-else-if="i === 'cash_fee_type'">{{ money[v] }}</h5>
              <h5 v-else-if="i === 'refund_status'">{{ refundStatus[v] }}</h5>
              <h5 v-else>
                {{ v === "SUCCESS" ? "成功" : v === "FAIL" ? "失败" : v }}
              </h5>
            </div>
          </div>
          <div class="right_con" v-if="code_img_url">
            <img class="codeImg" :src="code_img_url" alt="加载失败" />
          </div>
        </div>
      </div>
      <div class="bottom" style="margin-bottom: 18px" v-if="crumbs == '下单'">
        <el-button @click="onSubmit(formData1)" class="next" type="primary"
          >下单</el-button
        >
      </div>
      <div
        class="bottom"
        style="margin-bottom: 18px"
        v-if="crumbs == '订单查询'"
      >
        <el-button @click="onSearch(formData2)" class="next" type="primary"
          >订单查询</el-button
        >
      </div>
      <div class="bottom" style="margin-bottom: 18px" v-if="crumbs == '退款'">
        <el-button @click="onRefund(formData3)" class="next" type="primary"
          >退款</el-button
        >
      </div>
      <div
        class="bottom"
        style="margin-bottom: 18px"
        v-if="crumbs == '退款查询'"
      >
        <el-button
          @click="onRefundSearch(formData4)"
          class="next"
          type="primary"
          >退款查询</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import md5 from "js-md5";
import { getBank } from "@/utils/bank.js";
import { getMoney } from "@/utils/money.js";
import { mapState, mapMutations } from "vuex";
import { payproductsDrop } from "@/api/common.js";
import { URL_API } from "@/utils/apiUrl.js";

export default {
  data() {
    return {
      crumbs: "下单",
      // 下单
      formData1: {
        // key: 'Q6fzSA6t9VssQ6HJEjNPaeFWip7ydsAK',
        key: "", //密钥
        body: "测试商品",
        // mch_id: '19070001',
        mch_id: "",
        // notify_url: 'https://api.youths.run/v1/callback/test',
        notify_url: "https://apitest.youths.run/v1/callback/test",
        out_trade_no: "",
        spbill_create_ip: "1.1.1.1",
        total_fee: 0.01,
        trade_type: "trade.weixin.native", //产品类型
        cashier_type: 0, //收银台
        attach: "", //附加数据
        op_shop_no: "", //门店编号
      },
      // 订单查询
      formData2: {
        mch_id: "",
        transaction_id: "",
        out_trade_no: "",
        key: "", //密钥
      },
      // 退款
      formData3: {
        mch_id: "",
        transaction_id: "",
        out_trade_no: "",
        refund_fee: "",
        total_fee: "",
        notify_url: "",
        out_refund_no: "",
        key: "", //密钥
      },
      // 退款查询
      formData4: {
        mch_id: "",
        out_refund_no: "",
        transaction_id: "",
        out_trade_no: "",
        refund_id: "",
        key: "", //密钥
      },
      // key:'Q6fzSA6t9VssQ6HJEjNPaeFWip7ydsAK',
      key: "",
      detail: "",
      code_img_url: "",
      productName: "", //交易类型
      detailName: {
        return_code: "返回状态码",
        return_msg: "返回信息",
        mch_id: "商户号",
        nonce_str: "随机字符串",
        sign: "签名",
        result_code: "业务结果",
        err_code: "响应码",
        err_code_des: "响应码描述",
        trade_type: "交易类型",
        prepay_id: "预支付标识",
        prepay_url: "预支付请求参数",
        package_json: "预支付数据包",
        code_url: "二维码地址",
        code_img_url: "二维码图片",
        openid: "用户标识",
        is_subscribe: "是否关注公众账号",
        sub_openid: "用户子标识",
        sub_is_subscribe: "是否关注子公众账号",
        trade_state: "交易状态",
        bank_type: "付款银行",
        total_fee: "总金额(元)",
        fee_type: "货币种类",
        cash_fee: "现金支付金额(元)",
        cash_fee_type: "现金支付货币类型",
        transaction_id: "交易流水号",
        third_trans_id: "三方订单号",
        out_trade_no: "商户订单号",
        attach: "附加数据",
        time_end: "支付完成时间",
        trade_state_desc: "交易状态描述",
        out_refund_no: "退款订单号",
        refund_id: "退款流水号",
        third_refund_id: "三方退款单号",
        refund_channel: "退款渠道",
        refund_fee: "退款金额(元)",
        refund_status: "退款状态",
      },
        // API: "https://apitest.youthsrun.com/",
      API: URL_API.placeOrderUrl,
      //   payDropLists: {
      //     "trade.weixin.jspay": "微信公众号支付",
      //     "trade.weixin.native": "微信扫码支付",
      //     "trade.weixin.h5pay": "微信H5支付",
      //     "trade.weixin.wappay": "微信wap支付",
      //     "trade.alipay.native": "支付宝扫码支付",
      //     "trade.alipay.h5pay": "支付宝H5支付",
      //     "trade.qqpay.native": "QQ钱包扫码支付",
      //     "trade.qqpay.jspay": "QQ钱包公众号支付",
      //     "trade.qqpay.h5pay": "QQ钱包H5支付",
      //     "trade.baidu.native": "百度钱包扫码支付",
      //     "trade.jdpay.native": "京东钱包扫码支付",
      //     "trade.jdpay.h5pay": "京东钱包H5支付",
      //   },
      payDropLists: [], //产品类型数据
      payStatus: {
        SUCCESS: "支付成功",
        REFUND: "转入退款",
        NOTPAY: "未支付",
        CLOSED: "已关闭",
        REVOKED: "已撤销（刷卡支付）",
        USERPAYING: "用户支付中",
        PAYERROR: "支付失败（其他原因，如银行返回失败）",
      },
      refundStatus: {
        SUCCESS: "退款成功",
        FAIL: "退款失败",
        PROCESSING: "退款处理中",
      },
      bank: getBank(),
      money: getMoney(),
    };
  },
  computed: {
    ...mapState({
      testDetail: "operation_test",
    }),
  },
  created() {
    if (this.testDetail) {
      this.formData1 = this.testDetail.formData1;
      this.formData2 = this.testDetail.formData2;
      this.formData3 = this.testDetail.formData3;
      this.formData4 = this.testDetail.formData4;
    }
    this.getDrop();
  },
  methods: {
    ...mapMutations({
      setTest: "operation_setTest",
    }),
    // 获取产品类型数据下拉
    getDrop() {
      payproductsDrop().then((res) => {
        if (res.resultStatus) {
          this.payDropLists = res.resultData;
          console.log("产品类型数据", this.payDropLists);
        }
      });
    },
    getRoles() {
      if (this.crumbs === "下单") {
        if (!this.formData1.mch_id) {
          this.$message.error("请输入商户号");
          return false;
        }
        if (!this.formData1.body) {
          this.$message.error("请输入商品名称");
          return false;
        }
        if (!this.formData1.out_trade_no) {
          this.$message.error("请输入商户订单号");
          return false;
        }
        if (!this.formData1.total_fee) {
          this.$message.error("请输入总金额");
          return false;
        }
        if (+this.formData1.total_fee < 0.01) {
          this.$message.error("总金额至少为0.01元");
          return false;
        }
        if (!this.formData1.notify_url) {
          this.$message.error("请输入通知地址");
          return false;
        }
        if (!this.formData1.spbill_create_ip) {
          this.$message.error("请输入终端IP");
          return false;
        }
        if (!this.formData1.trade_type) {
          this.$message.error("请选择交易类型");
          return false;
        }
        if (!this.formData1.key) {
          this.$message.error("请输入密钥");
          return false;
        }
        return true;
      }
      if (this.crumbs === "订单查询") {
        if (!this.formData2.mch_id) {
          this.$message.error("请输入商户号");
          return false;
        }
        if (!this.formData2.transaction_id && !this.formData2.out_trade_no) {
          this.$message.error("请输入交易流水号或商户订单号");
          return false;
        }
        if (!this.formData2.key) {
          this.$message.error("请输入密钥");
          return false;
        }
        return true;
      }
      if (this.crumbs === "退款") {
        if (!this.formData3.mch_id) {
          this.$message.error("请输入商户号");
          return false;
        }
        if (!this.formData3.transaction_id && !this.formData3.out_trade_no) {
          this.$message.error("请输入交易流水号或商户订单号");
          return false;
        }
        if (!this.formData3.total_fee) {
          this.$message.error("请输入总金额");
          return false;
        }
        if (+this.formData3.total_fee < 0.01) {
          this.$message.error("总金额至少为0.01元");
          return false;
        }
        if (!this.formData3.refund_fee) {
          this.$message.error("请输入退款金额");
          return false;
        }
        if (+this.formData3.refund_fee < 0.01) {
          this.$message.error("退款金额至少为0.01元");
          return false;
        }
        if (!this.formData3.out_refund_no) {
          this.$message.error("请输入退款订单号");
          return false;
        }
        if (!this.formData3.notify_url) {
          this.$message.error("请输入通知地址");
          return false;
        }
        if (!this.formData3.key) {
          this.$message.error("请输入密钥");
          return false;
        }
        return true;
      }
      if (this.crumbs === "退款查询") {
        if (!this.formData4.mch_id) {
          this.$message.error("请输入商户号");
          return false;
        }
        if (!this.formData4.out_refund_no) {
          this.$message.error("请输入退款订单号");
          return false;
        }
        if (
          !this.formData4.transaction_id &&
          !this.formData4.out_trade_no &&
          !this.formData4.refund_id
        ) {
          this.$message.error("请输入交易流水号或商户订单号或退款流水号");
          return false;
        }
        if (!this.formData4.key) {
          this.$message.error("请输入密钥");
          return false;
        }
        return true;
      }
    },
    // 下单
    onSubmit(value) {
      if (!this.getRoles()) {
        return;
      }
      let data = JSON.parse(JSON.stringify(value));
      this.key = data.key;
      delete data.key;
      if (!data.attach) {
        delete data.attach;
      }
      if (!data.op_shop_no) {
        delete data.op_shop_no;
      }
      data.total_fee = Math.floor(data.total_fee * 100);
      //   console.log("获取下单签名的参数为", data);

      let sortData = this.sortASCII(data);
      //   console.log("下单签名", sortData);

      let xmlData = `<xml>`;
      for (let key in sortData) {
        xmlData += `<${key}>${sortData[key]}</${key}>`;
      }
      xmlData += `</xml>`;
      //   console.log("下单请求参数", xmlData);

      this.request("v1/pay/unifiedorder", xmlData).then((res) => {
        this.detail = res;
        console.log("下单请求结果", this.detail);
        // 获取交易类型
        this.payDropLists.forEach((e) => {
          if (this.detail && this.detail.trade_type == e.productValue) {
            this.productName = e.productName;
          }
        });
        if (res.code_img_url) {
          this.code_img_url = res.code_img_url;
        }
      });
    },
    // 订单查询
    onSearch(value) {
      if (!this.getRoles()) {
        return;
      }
      let data = JSON.parse(JSON.stringify(value));
      this.key = data.key;
      delete data.key;
      let xmlData = `<xml>`;
      if (data.transaction_id) delete data.out_trade_no;
      if (!data.transaction_id && data.out_trade_no) {
        delete data.transaction_id;
      }

      let sortData = this.sortASCII(data);

      for (let key in sortData) {
        xmlData += `<${key}>${sortData[key]}</${key}>`;
      }
      xmlData += `</xml>`;
      this.request("v1/pay/orderquery", xmlData).then((res) => {
        this.detail = res;
      });
    },
    // 退款
    onRefund(value) {
      if (!this.getRoles()) {
        return;
      }
      let data = JSON.parse(JSON.stringify(value));
      this.key = data.key;
      delete data.key;
      data.total_fee = Math.floor(data.total_fee * 100);
      data.refund_fee = Math.floor(data.refund_fee * 100);
      let xmlData = `<xml>`;
      if (data.transaction_id) delete data.out_trade_no;
      if (!data.transaction_id && data.out_trade_no) {
        delete data.transaction_id;
      }
      let sortData = this.sortASCII(data);
      for (let key in sortData) {
        xmlData += `<${key}>${sortData[key]}</${key}>`;
      }
      xmlData += `</xml>`;
      this.request("v1/pay/refund", xmlData).then((res) => {
        this.detail = res;
      });
    },
    // 退款查询
    onRefundSearch(value) {
      if (!this.getRoles()) {
        return;
      }
      let param = JSON.parse(JSON.stringify(value));
      this.key = param.key;
      delete param.key;
      let xmlData = `<xml>`;

      if (param.transaction_i) {
        delete param.out_trade_no;
        delete param.refund_id;
      }
      if (!param.transaction_id) {
        delete param.transaction_id;
        delete param.refund_id;
      }
      if (param.refund_id) {
        delete param.transaction_id;
        delete param.out_trade_no;
      }

      let sortData = this.sortASCII(param);
      for (let key in sortData) {
        xmlData += `<${key}>${sortData[key]}</${key}>`;
      }
      xmlData += `</xml>`;
      this.request("v1/pay/refundquery", xmlData).then((res) => {
        this.detail = res;
      });
    },
    // 请求封装
    request(url, value) {
      return new Promise((resolve, reject) => {
        let ajax = new XMLHttpRequest();
        ajax.open("POST", this.API + url, true);
        ajax.setRequestHeader("Content-Type", "text/xml;charset=UTF-8");
        ajax.send(value);
        ajax.onreadystatechange = (res) => {
          if (res.target.readyState == 4) {
            if (res.target.status == 200) {
              let xmlToJSON = this.xml2json(res.target.responseXML);
              resolve(xmlToJSON.xml);
            }
          }
        };
      });
    },
    // xml转json
    xml2json(xml) {
      try {
        var obj = {};
        if (xml.children.length > 0) {
          for (var i = 0; i < xml.children.length; i++) {
            var item = xml.children.item(i);
            var nodeName = item.nodeName;

            if (typeof obj[nodeName] == "undefined") {
              obj[nodeName] = this.xml2json(item);
            } else {
              if (typeof obj[nodeName].push == "undefined") {
                var old = obj[nodeName];

                obj[nodeName] = [];
                obj[nodeName].push(old);
              }
              obj[nodeName].push(this.xml2json(item));
            }
          }
        } else {
          obj = xml.textContent;
        }
        return obj;
      } catch (e) {
        console.log(e.message);
      }
    },
    // 数据排序
    sortASCII(data) {
      let obj = { ...data };
      var arr = [];
      var num = 0;
      obj.nonce_str = "o" + Math.random();
      for (var i in obj) {
        arr[num] = i;
        num++;
      }
      var sortArr = arr.sort();
      var sortObj = {};
      for (var i in sortArr) {
        sortObj[sortArr[i]] = obj[sortArr[i]];
      }
      sortObj.sign = this.getSign(sortObj);
      return sortObj;
    },
    // 获取签名
    getSign(obj) {
      let sign = "";
      let stringA = "";
      let count = 0;
      for (let key in obj) {
        if (count == 0) {
          stringA += `${key}=${obj[key]}`;
          count++;
        } else {
          stringA += `&${key}=${obj[key]}`;
        }
      }
      //   console.log("密钥密钥", this.key);

      let stringSignTemp = `${stringA}&key=${this.key}`;
      //   console.log("stringSignTempstringSignTempstringSignTemp", stringSignTemp);

      sign = md5(stringSignTemp);
      return sign.toUpperCase();
    },
    navClick(data) {
      this.crumbs = data;
      this.detail = "";
      this.code_img_url = "";
    },
  },
  beforeDestroy() {
    let testDetail = {
      formData1: this.formData1,
      formData2: this.formData2,
      formData3: this.formData3,
      formData4: this.formData4,
    };
    this.setTest(testDetail);
  },
};
</script>

<style scoped>
@import "../../../assets/css/desc.css";
@import "../../../assets/css/editInput.css";


.next {
  width: 120px;
  height: 32px;
  background: rgba(72, 184, 182, 1);
  border-radius: 3px;
  border-color: #65b1ae;
  line-height: 10px;
  margin-left: 10px;
  padding: 0;
  font-size: 12px;
  color: #ffffff;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid #dce0e6;
  border-radius: 3px;
  padding: 0;
  font-size: 12px;
  color: #333333;
}

.bottom {
  margin-top: 50px;
  text-align: center;
}

.list {
  padding-left: 36px;
}

.list .left_con {
  float: left;
}

.list .left_con h4 {
  float: left;
  width: 150px;
  font-size: 14px;
  color: #999999;
  margin-bottom: 18px;
}

.list .left_con h5 {
  float: left;
  width: 510px;
  font-size: 14px;
  color: #333333;
}

.list .left_con h5 a {
  color: #2d77ff;
}

.list .right_con {
  float: left;
}

.codeImg {
  width: 200px;
  height: 200px;
}
</style>
